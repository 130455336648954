.loginContainer {
  display: flex;
  height: 90vh;
  justify-content: center;
  align-items: center;
}

.formContainer {
  background-color: white;
  width: 400px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 50px 50px;
}
