.bodyContainer {
  max-width: 100%;
}

img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

iframe {
  width: 100%;
  min-height: 47vh;
}
